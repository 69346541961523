<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>推广管理</a-breadcrumb-item>
    <a-breadcrumb-item>数据汇总</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <!-- 日期： -->
    <span class="tool-title">日期：</span>
    <a-range-picker
      v-model:value="dateRange"
      :format="dateFormat"
      :allowClear="false"
      @change="onDateChange"
    />
    <!-- 弹簧 -->
    <div style="flex: 1;"></div>
    <!-- 操作 -->
    <a-button @click="touchDownload">导出数据</a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="pagination"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <!-- 新户充值率 -->
      <template v-if="column.key === 'new_pay_user_rate'">
        {{ getNewPayUserRate(record) }}
      </template>
      <!-- 充值金额 / 新户充值金额 -->
      <template v-if="column.key === 'pay_amount' || column.key === 'new_pay_amount'">
        {{ record[column.key] === '-' ? '-' : $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) }}
      </template>
      <!-- 分成比例 -->
      <template v-if="column.key === 'cash_rate'">
        {{ record[column.key] === '-' ? '-' : $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0), 2, false, true) + '%' }}
      </template>
      <!-- 预计分成 -->
      <template v-if="column.key === 'cash_amount'">
        {{ record[column.key] === '-' ? '-' : $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) }}
      </template>
    </template>
    <!-- 汇总 -->
    <template #summary>
      <a-table-summary-row>
        <a-table-summary-cell v-for="(item, index) in columns" :key="index">
          <span v-if="item.key === 'new_pay_user_rate'">
            {{ getNewPayUserRate(summary) }}
          </span>
          <!-- 表格内处理的格式，在汇总数据里面需要再处理一遍 -->
          <span v-else-if="item.key === 'pay_amount' || item.key === 'new_pay_amount'">
            {{ summary[item.key] === '-' ? '-' : $pub.KEEP_NUMBER_DECIMAL((summary[item.key] || 0) / 100, 2, false, true) }}
          </span>
          <span v-else-if="item.key === 'cash_rate'">
            {{ summary[item.key] === '-' ? '-' : $pub.KEEP_NUMBER_DECIMAL((summary[item.key] || 0), 2, false, true) + '%' }}
          </span>
          <span v-else-if="item.key === 'cash_amount'">
            {{ summary[item.key] === '-' ? '-' : $pub.KEEP_NUMBER_DECIMAL((summary[item.key] || 0) / 100, 2, false, true) }}
          </span>
          <!-- 不需要处理的 -->
          <span v-else>
            {{ summary[item.key] || 0 }}
          </span>
        </a-table-summary-cell>
      </a-table-summary-row>
    </template>
  </a-table>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import dayjs from 'dayjs'

import * as Ex from "@netjoy/excelex"
import { advDataList } from '@/api/promotion'
import Pub from '@/utils/public'

// 加载
let isLoading = ref(false)
// 日期范围
let dateRange = ref([dayjs().subtract(30, 'day'), dayjs()])
// 日期format
const dateFormat = 'YYYY-MM-DD'
// 数据源
let dataSource = ref([])
// 汇总数据
let summary = ref({})
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '日期',
    dataIndex: 'date',
    key: 'date'
  },
  {
    title: '访问用户',
    dataIndex: 'visit_user_count',
    key: 'visit_user_count'
  },
  {
    title: '充值用户',
    dataIndex: 'pay_user_count',
    key: 'pay_user_count'
  },
  {
    title: '充值金额',
    dataIndex: 'pay_amount',
    key: 'pay_amount'
  },
  // {
  //   title: '新增用户',
  //   dataIndex: 'new_user_count',
  //   key: 'new_user_count'
  // },
  // {
  //   title: '充值新户',
  //   dataIndex: 'new_pay_user_count',
  //   key: 'new_pay_user_count'
  // },
  // {
  //   title: '新户充值率',
  //   dataIndex: 'new_pay_user_rate',
  //   key: 'new_pay_user_rate'
  // },
  // {
  //   title: '新户充值金额',
  //   dataIndex: 'new_pay_amount',
  //   key: 'new_pay_amount'
  // },
  {
    title: '分成比例',
    dataIndex: 'cash_rate',
    key: 'cash_rate'
  },
  {
    title: '预计分成',
    dataIndex: 'cash_amount',
    key: 'cash_amount'
  }
])

// 钩子函数
onBeforeMount (() => {
  // 获取推广数据列表
  getAdvDataList()
})

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getAdvDataList()
}

// 切换日期
function onDateChange () {
  // 设置分页
  pagination.current = 1
  // 数据
  getAdvDataList()
}

// 获取推广数据列表
function getAdvDataList () {
  isLoading.value = true
  const params = {
    start_at: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_at: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  advDataList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
      // 汇总数据
      summary.value = data.summary
    } else {
      message.error(res.message || msg)
    }
  })
}

// 点击导出数据
function touchDownload () {
  isLoading.value = true
  const params = {
    start_at: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_at: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    page: 1,
    page_size: 100000
  }
  advDataList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      // 将汇总数据插入第一条
      const list = [data.summary].concat(data.data)
      if (list.length) {
        const columns = [
          {
            name: '日期',
            field: 'date'
          },
          {
            name: '访问用户',
            field: 'visit_user_count'
          },
          {
            name: '充值用户',
            field: 'pay_user_count'
          },
          {
            name: '充值金额',
            field: 'pay_amount'
          },
          // {
          //   name: '新增用户',
          //   field: 'new_user_count'
          // },
          // {
          //   name: '充值新户',
          //   field: 'new_pay_user_count'
          // },
          // {
          //   name: '新户充值率',
          //   field: 'new_pay_user_rate'
          // },
          // {
          //   name: '新户充值金额',
          //   field: 'new_pay_amount'
          // },
          {
            name: '分成比例',
            field: 'cash_rate'
          },
          {
            name: '预计分成',
            field: 'cash_amount'
          }
        ]
        const sheets = [
          {
            data: list,
            columns: columns
          }
        ]
        Ex.ex(sheets, function (item, field, json, sheetIndex, row, col, rowCount, colCount) {
          // row=0 为表头
          if (row != 0) {
            // 金额处理
            if (field === 'pay_amount' || field === 'new_pay_amount' || field === 'cash_amount') {
              if (item.data != '-') {
                item.data = Pub.KEEP_NUMBER_DECIMAL(item.data / 100, 2, false, true)
              }
              return item
            } else if (field === 'new_pay_user_rate') {
              if (item.data != '-') {
                item.data = getNewPayUserRate(json)
              }
              return item
            } else if (field === 'cash_rate') {
              if (item.data != '-') {
                item.data = Pub.KEEP_NUMBER_DECIMAL(item.data, 2, false, true) + '%'
              }
              return item
            }
          }
          // 无数据兼容
          // item.data = item.data || 0
          return item
        },  `推广数据`)
      } else {
        message.error('还没有可导出的内容')
      }
    } else {
      message.error('导出失败')
    }
  }).catch((err) => {
    message.error('导出失败')
    console.log(err)
  })
}

// 计算新户充值率
function getNewPayUserRate (json) {
  var new_user_count = json['new_user_count']
  var new_pay_user_count = json['new_pay_user_count']
  if (new_user_count === '-' || new_pay_user_count === '-') {
    return '-'
  } else {
    return Pub.KEEP_NUMBER_DECIMAL(new_pay_user_count / new_user_count * 100, 1, false, true) + '%'
  }
}

</script>

<style scoped>
.tool-view {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

</style>